import compact from 'lodash/fp/compact';
import concat from 'lodash/fp/concat';
import debounce from 'lodash/fp/debounce';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import toString from 'lodash/fp/toString';
import uniqBy from 'lodash/fp/uniqBy';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Grid } from '@hero-design/react';

import { requiredFieldNotEmpty } from '@packages/eh-utils/formValidators';
import { SelectInput, TextInput } from '@packages/hero-theme/form';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';

import useFetchUserProfile from 'src/modules/User/hooks/useFetchUserProfile';
import useMergeState from 'src/shared/hooks/useMergedState';

import useFetchAllCountries from '../../modules/CareersPage/hooks/useFetchAllCountries';
import useFetchLocationName from '../../modules/CareersPage/hooks/useFetchLocationName';
import { onFilterChangeSWR } from '../../modules/CareersPage/utils/helpers';
import { FormatLocation, InitLocation, Location, LocationForm } from '../types';

const OTHER_COUNTRIES_CODE = 'ZZ';
const MAXIMUM_LOCATION_OPTIONS = 20;

export const appendInitOption =
  (initOption: InitLocation | null) => (options: Array<InitLocation>) => {
    if (!initOption) {
      return options;
    }

    const isOptionIncluded =
      initOption != null &&
      options.find(({ value }: InitLocation) => value === initOption.value);

    if (isOptionIncluded) {
      return options;
    }

    return concat(options)(initOption);
  };

export const getLocationsByCountryCode =
  (countryCode: string | undefined) => (locations: Array<Location>) => {
    if (countryCode === OTHER_COUNTRIES_CODE) return locations;

    return filter(
      (location: Location) => location?.countryCode === countryCode
    )(locations);
  };

export const formatLocation = ({
  id,
  place_name,
  region,
  state,
  postal_code,
  country_code,
  latitude,
  longitude,
}: FormatLocation) => {
  const additionalInfo =
    postal_code !== '0'
      ? `, ${region || state || ''} ${postal_code || ''} `.trim()
      : `, ${region || state || ''}`.trim();

  const text = `${place_name}${
    additionalInfo.endsWith(',') ? '' : additionalInfo
  }`;

  return {
    value: id,
    text,
    countryCode: country_code,
    latitude,
    longitude,
  };
};

export const getInitOption = (initCandidateInfo: LocationForm) => {
  const initVendorLocationId = initCandidateInfo?.vendorLocationId;

  return initVendorLocationId != null
    ? {
        value: initCandidateInfo?.vendorLocationId,
        text: initCandidateInfo?.vendorLocationName,
        countryCode: initCandidateInfo?.countryCode,
      }
    : null;
};

const LocationField = () => {
  const { control, setValue, getValues } = useFormContext<LocationForm>();
  const initJobInfo = getValues();
  const [initLocationOption, setInitLocationOption] = useState(
    getInitOption(initJobInfo)
  );
  const { permissionsData } = useFetchPermissions();

  const improveCandidateDataEnabled =
    !!permissionsData?.data?.improve_candidate_data;

  const countryCode = useWatch({
    control,
    name: 'countryCode',
  });
  const vendorLocationId = useWatch({
    control,
    name: 'vendorLocationId',
  });

  const { rawCountriesData, isLoadingCountries } = useFetchAllCountries();
  const [queryParams, setQueryParams] = useMergeState({
    country_code: countryCode,
    query: '',
    location_count: MAXIMUM_LOCATION_OPTIONS,
  });
  const { rawLocationData, isLoadingLocationData } = useFetchLocationName({
    queryParams,
  });
  const { userProfileData, isFetchingUserProfile } = useFetchUserProfile();

  const countryOptions = useMemo(
    () =>
      map(({ id, name }) => ({
        value: id,
        text: name,
      }))(rawCountriesData?.data?.items),
    [rawCountriesData]
  );

  const locationOptions = useMemo(
    () =>
      flow(
        map(formatLocation),
        appendInitOption(initLocationOption),
        getLocationsByCountryCode(countryCode),
        uniqBy('value'),
        compact
      )(rawLocationData?.data?.items),
    [rawLocationData, countryCode, initLocationOption]
  );

  const onLocationInputChange = useCallback(
    debounce(300)(input => {
      if (isEmpty(input) || input.trim().length < 3) return;

      onFilterChangeSWR({
        setQueryParams,
        queryParams,
      })({
        country_code: countryCode || 'AU',
        query: input,
      });
    }),
    [queryParams, countryCode]
  );

  const updateLocationName = useCallback(
    (locationId: any) => {
      const location = locationOptions?.find(
        ({ value }: InitLocation) => toString(value) === toString(locationId)
      );

      setValue('vendorLocationName', location?.text);
    },
    [locationOptions, setValue]
  );

  const clearFields = useCallback(
    (fieldName: any) => {
      setValue(fieldName, '');
    },
    [setValue]
  );

  useEffect(
    () => {
      if (vendorLocationId != null) {
        updateLocationName(vendorLocationId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vendorLocationId]
  );

  useEffect(
    () => {
      if (
        countryCode !== userProfileData?.data?.country_code &&
        !isFetchingUserProfile
      ) {
        clearFields('vendorLocationId');
        clearFields('vendorLocationName');
        setInitLocationOption(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryCode]
  );

  return (
    <>
      <Grid.Row gutter={['large', 'small']}>
        <Grid.Col span={[24, 24, 12, 12, 12]}>
          <Controller<LocationForm, 'countryCode'>
            name="countryCode"
            render={({ field, fieldState }) => (
              <SelectInput
                field={field}
                fieldState={fieldState}
                inputProps={{
                  placeholder: 'Country',
                  loading: isLoadingCountries,
                  options: countryOptions || [],
                }}
                labelProps={{ text: 'Country', required: true }}
                extraProps={{
                  'data-test-id': 'country-field',
                }}
              />
            )}
            rules={{ validate: { requiredFieldNotEmpty } }}
          />
        </Grid.Col>

        <Grid.Col span={[24, 24, 12, 12, 12]}>
          <Controller<LocationForm, 'vendorLocationId'>
            name="vendorLocationId"
            render={({ field, fieldState }) => (
              <SelectInput
                field={field}
                fieldState={fieldState}
                inputProps={{
                  placeholder: 'Please enter 3 or more characters',
                  loading: isLoadingLocationData,
                  options: locationOptions || [],
                  disabled: isEmpty(countryCode),
                }}
                labelProps={{
                  text: 'City',
                  required: improveCandidateDataEnabled,
                }}
                extraProps={{
                  queryable: true,
                  onQueryChange: onLocationInputChange,
                  'data-test-id': 'location-field',
                }}
              />
            )}
            rules={{
              validate: improveCandidateDataEnabled
                ? { requiredFieldNotEmpty }
                : undefined,
            }}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row gutter={['medium', 'none']} style={{ display: 'none' }}>
        <Controller<LocationForm, 'vendorLocationName'>
          name="vendorLocationName"
          render={({ field, fieldState }) => (
            <TextInput field={field} fieldState={fieldState} inputProps={{}} />
          )}
        />
      </Grid.Row>
    </>
  );
};

export default LocationField;
