import { useMemo } from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { ApiConfigs, useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import { LocationsList, QueryParams } from '../types';

type LocationsResponse = {
  data: LocationsList;
};

type TFetchLocationNameProps = Pick<
  ApiConfigs<LocationsResponse, QueryParams>,
  'onCompleted'
> & {
  queryParams?: QueryParams;
  configs?: SWRConfiguration;
};

const useFetchLocationName = ({
  onCompleted,
  configs = {},
  queryParams = {},
}: TFetchLocationNameProps) => {
  const { fetcher } = useFetch<LocationsResponse, QueryParams>({
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/countries/${
      queryParams.country_code
    }/locations`,
    method: 'GET',
    withSWR: true,
    onCompleted,
  });

  const finalQuery = useMemo(
    () => ({
      count: queryParams.location_count,
      query: queryParams.query,
    }),
    [queryParams]
  );

  const invalidQuery = (queryParams.query || '').trim().length < 3;

  const {
    data: rawLocationData,
    mutate: fetchLocationData,
    error: locationError,
  } = useSWR<LocationsResponse>(
    queryParams.country_code && !invalidQuery
      ? {
          key: 'locationsByCountry',
          params: finalQuery,
        }
      : null,
    ({ params }) => fetcher(params),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...configs,
    }
  );

  return {
    rawLocationData,
    fetchLocationData,
    locationError,
    isLoadingLocationData: !rawLocationData && !locationError && !invalidQuery,
  };
};

export default useFetchLocationName;
